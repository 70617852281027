<template>
  <div class="main" data-title="战败审核" v-title>
    <div class="btn-sticky">
      <span @click="popupVisible = true">审核</span>
    </div>
    <trace-detail ref="trace"></trace-detail>

    <van-popup
      class="round-popup"
      position="bottom"
      style="height: 50%; width: 100%;"
      v-model="popupVisible"
    >
      <div class="cell-group">
        <van-field label="审核结果" name="radio">
          <template #input>
            <van-radio-group
              direction="horizontal"
              v-model="form.confirm_status"
            >
              <van-radio :name="1">同意</van-radio>
              <van-radio :name="2">不同意</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field
          autosize
          label="审核意见"
          placeholder="请输入"
          rows="2"
          type="textarea"
          v-model="form.confirm_memo"
        />
      </div>
      <!-- </van-cell-group> -->
      <div class="cell-group">
        <field-select
          :columns="sellArr()"
          :label="'销售顾问'"
          isLink
          placeholder="请选择"
          v-model="seller_uid"
        ></field-select>
      </div>
      <div class="btn">
        <van-button @click="onSubmit" block round type="info">提交</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { sellList } from '@/unit/unit.js'
import TraceDetail from '@/components/TraceDetail.vue'
import FieldSelect from '@/components/FieldSelect.vue'
export default {
  components: {
    TraceDetail,
    FieldSelect,
  },
  data() {
    return {
      popupVisible: false,
      seller_uid: '',
      form: {
        trace_id: this.$route.query.trace_id,
        sys_id: this.$route.query.sys_id,
        confirm_status: 1,
        confirm_memo: '',
      },
    }
  },
  mounted(){
    this.$refs.trace.show(this.$route.query.trace_id)
  },
  methods: {
    sellArr() {
      return sellList()
    },
    showPopup() {
      this.popupVisible = true
      this.seller_uid = ''
      this.form.check_memo = ''
    },
    closePopup() {
      this.seller_uid = ''
      this.form.check_memo = ''
      this.popupVisible = false
    },
    onSubmit() {
      this.$axios.post('/trace/confirm', this.form).then((res) => {
        if (res.data.code == 200) {
          this.$notify({ type: 'success', message: res.data.msg })
          this.$router.push({ name: 'company-task' })
        } else {
          this.$notify({ type: 'danger', message: res.data.msg })
        }
      })

      if (this.seller_uid != '') {
        this.$axios.post('/client/change_seller', {
          client_ids: [this.client_id],
          seller_uid: this.seller_uid,
          memo: '战败审核变更销售顾问',
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.items {
  display: flex;
  align-items: center;
  .item {
    flex: 1;
    display: flex;
    .label {
      padding: 3px 8px 3px 0;
      color: #606266;
    }
    .val {
      padding: 3px 0;
      font-weight: 500;
    }
  }
}
.btn-sticky {
  display: flex;
  position: fixed;
  padding: 10px 20px;
  right: 0px;
  color: #1989fa;
  z-index: 999;
}
.btn {
  padding: 0 20px;
}
</style>